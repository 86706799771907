<!--
 * @Description: 添加城市弹框
 * @Author: ChenXueLin
 * @Date: 2021-09-10 15:20:48
 * @LastEditTime: 2022-07-01 09:27:23
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="区域转移"
    :visible.sync="moveAreaVisible"
    width="600px"
    :before-close="handleClose"
    :close-on-click-modal="false"
    v-loading="dialogLoading"
    :element-loading-background="loadingBackground"
    custom-class="custom-dialog"
    @open="dialogOpen"
  >
    <el-form
      ref="moveAreaForm"
      label-width="120px"
      :model="moveAreaForm"
      :rules="moveAreaFormRules"
    >
      <el-form-item label="大区名称" prop="regionalId">
        <e6-vr-select
          v-model="moveAreaForm.regionalId"
          :data="regionalList"
          placeholder="大区名称"
          title="大区名称"
          :props="{
            id: 'areaId',
            label: 'areaName'
          }"
          @change="handleRegional"
        ></e6-vr-select>
      </el-form-item>
      <el-form-item label="片区名称" prop="targetSectionAreaId">
        <e6-vr-select
          v-model="moveAreaForm.targetSectionAreaId"
          :data="areaList"
          placeholder="片区名称"
          title="片区名称"
          clearable
          :props="{
            id: 'areaId',
            label: 'areaName'
          }"
        ></e6-vr-select>
      </el-form-item>
    </el-form>
    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="handleClose">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import { getAreaDown, moveArea } from "@/api";
export default {
  name: "addCity",
  components: {},
  data() {
    return {
      dialogLoading: false,
      regionalList: [], //大区
      areaList: [], //片区
      moveAreaForm: {
        regionalId: "", //大区id
        targetSectionAreaId: "" //片区id
      },
      moveAreaFormRules: {
        regionalId: [
          {
            required: true,
            message: "请选择大区",
            trigger: ["blur", "change"]
          }
        ],
        targetSectionAreaId: [
          {
            required: true,
            message: "请选择片区",
            trigger: ["blur", "change"]
          }
        ]
      }
    };
  },
  props: ["moveAreaVisible", "areaIdList"],
  mixins: [base],
  computed: {},
  watch: {},
  methods: {
    //打开弹框
    dialogOpen() {
      this.getAreaDownList();
      this.areaList = [];
    },
    //获取大区和片区列表
    async getAreaDownList(parentId = "") {
      try {
        let res = await getAreaDown({ parentId });
        if (this.moveAreaForm.regionalId) {
          //片区
          this.areaList = this.getFreezeResponse(res, {
            path: "data"
          });
        } else {
          //大区下拉框
          this.regionalList = this.getFreezeResponse(res, {
            path: "data"
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //选中大区
    handleRegional(flag, node) {
      this.getAreaDownList(node.areaId);
    },
    confirm() {
      this.$refs.moveAreaForm.validate(valid => {
        if (valid) {
          this.moveAreaReq();
        }
      });
    },
    //区域转移请求
    async moveAreaReq() {
      try {
        this.dialogLoading = true;
        let res = await moveArea({
          ...this.moveAreaForm,
          areaIdList: this.areaIdList
        });
        if (res.code == "OK") {
          this.$message.success("转移成功");
          this.$emit("refresh");
          this.handleClose();
        }
      } catch (error) {
        printError(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    handleClose() {
      this.moveAreaForm = {
        regionalId: "", //大区id
        targetSectionAreaId: "" //片区id
      };
      this.$refs.moveAreaForm.resetFields();
      this.$emit("handleClose", "moveAreaVisible");
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
/deep/.custom-dialog {
  .el-dialog__body {
    padding: 20px 18px 2px 18px;
    .el-input {
      width: 280px;
    }
  }
}
</style>
